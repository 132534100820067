import { useEffect } from "react";

const useScript = (id: string, content = "", url = "") => {
  useEffect(() => {
    const script = document.createElement("script");

    script.id = id;

    if (content) {
      script.innerHTML = content;
    }
    if (url) {
      script.src = url;
    }
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [id, url, content]);
};

export default useScript;
